.validate-frame {
  margin: 0 auto;
  width: 40%;
  height:fit-content;
  border-radius: 24px;
  box-shadow: 0px 0px 12px 0px rgba(36, 36, 36, 0.33);
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  background-color: white;
  max-width: 501px;

  @media (max-width: 700px) {
    width: 90%;
  }
}

.floatRight {
  float:right;
}

.floatLeft {
  float: left;
}

.floatLeft-menu {
  float: left;
  line-height: 32px;
}

.right-divider {
  margin-left: 3px;
  margin-right: 3px;
}