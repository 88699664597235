.payment-receipt-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  padding-top: 0px;
  margin: 40px auto 0px auto;
  width: 744px;
  height: fit-content;
  background: #ffffff;
  border-radius: 24px;
  gap: 24px;
}

.payment-receipt-frame-header {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 0px;
}

.payment-receipt-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 100px 0px 100px;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;
}

.payment-receipt-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
}

.payment-receipt-title {
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  color: #242424;
}

.payment-receipt-subtitle {
  width: 664px;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.payment-receipt-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.payment-receipt-details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 30px;
}

.payment-receipt-details-left-accno-label-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  //width: 120px;
  height: 24px;
}

.payment-receipt-details-left-label {
  //width: 178px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #242424;
}

.payment-receipt-details-left-service-fee-label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  // width: 81px;
  height: 24px;
}

.payment-receipt-details-right-accno-value-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 120px;
  height: 24px;
}

.payment-receipt-details-right-label {
  // width: 90px;
  height: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #242424;
}

.payment-receipt-payment-total-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  background: #ebebeb;
  border-radius: 0px;
  padding: 0px 100px;
}

.payment-receipt-sub-title {
  line-height: 24px;
}

.success {
  background: #07894a;
}

.scheduled {
  background: #555555;
}

.pending {
  background: #ebebeb;
}

.error {
  background: #ba3537;
}

.payment-receipt-payment-total-label {
  width: 100%;
  height: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.payment-receipt-payment-total-value {
  width: 100%;
  height: 32px;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ffffff;
}

.successText {
  color: #006d39;
}

.scheduledText {
  color: #ffffff;
}

.processingText {
  color: #ffffff;
}

.pendingText {
  color: #006d39;
}

.payment-receipt-buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 48px;

  width: 100%;
  height: 48px;
}

.payment-receipt-button-item {
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.cancel-payment {
  border-radius: 40px;
  border: 2px solid #007fad;
}

.cancel-payment:hover {
  border-radius: 40px;
  border: none;
}

@media screen and (max-width: 479px) {
  .payment-receipt-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    padding-top: 0px;
    gap: 24px;
    margin: auto;
    width: 100%;
    height: fit-content;
    background: #ffffff;
    border-radius: 0px;
  }

  .payment-receipt-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 24px;
    width: 100%;
  }

  .payment-receipt-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
  }

  .payment-receipt-title {
    width: 100%;
    font-weight: 900;
    font-size: 24px;
    line-height: 40px;
    color: #242424;
  }

  .payment-receipt-subtitle {
    width: 100%;
    height: fit-content;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .payment-receipt-buttons-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 30px;
    gap: 48px;

    width: 100%;
    height: 48px;
  }

  .payment-receipt-payment-total-container {
    padding: 0px 24px;
  }
}