.retailer-pop-over {
  z-index: 999;
  background-color: white;
  overflow-y: auto;
  position: fixed;
  top: 48%;
  left: 15%;
  transform: translate(-50%, -50%);
  width: 25%;
  border: 1px solid lightgrey;
  min-height: 200px;
  max-height: 90%;
  opacity: 1;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  max-width: 75%;
  min-width: 400px;
  border-radius: 24px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.retailer-item-label {
  align-self: stretch;
  color: #242424;
  font-family: Roboto, Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.retailer-item-label-large {
  align-self: stretch;
  color: #242424;
  font-family: Roboto, Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

@media screen and (max-width: 768px) {
  .retailer-pop-over {
    z-index: 999;
    background-color: white;
    padding-top: 5px;
    padding-bottom: 0px;
    overflow-y: auto;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    width: 100vw;
    border: 1px solid lightgrey;
    min-height: 200px;
    opacity: 1;
    box-shadow: none;
    border-radius: 0px;
    overflow: auto;
    max-height: 100vh;
  }

  .retailer-locations-accordion {
    height: 20px !important;
  }

  .retailer-item-label-large {
    text-align: left;
    font-size: 14px;
  }

  .retailer-item-label {
    text-align: left;
    font-size: 12px;
  }
}
