.credit-debit-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 24px;
  // width: 600px;
  overflow: hidden;
  width: 600px;
  max-width: 600px;
}

.credit-debit-subtitle {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0
}

.first-name-last-name-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0px;
  grid-gap: 40px;
  gap: 40px;
  width: 100%;
}

.first-name-last-name-textbox {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  grid-gap: 4px;
  gap: 4px;
  width: 100%;
}

.credit-debit-number-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  gap: 4px;
  width: 100%;
}

.expiry-cvc-zip-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0px;
  grid-gap: 40px;
  gap: 40px;
  width: 100%;


}

.expiry-cvc-zip-textbox {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  gap: 4px;
  max-width: 150px;
  //height: 92px;

}

.save-card-toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  margin-top: -22px;
}

.toggle-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.label-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 0.4rem;
  gap: 4px;
}

.label-text {
  color: #2A3141;
  font-family: Roboto, Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.save-card-inputs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  max-width: 520px;
}

.save-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 16px;
  background: #F1F9FF;
  border: 1px solid #767676;
  border-radius: 8px;
}

.save-card-container-enabled {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 16px;
  background: #CCFFD6;
  border: 2px solid #07894A;
  border-radius: 8px;
}

.save-card-title-toggle-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.save-card-title {
  width: 408px;
  height: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #14181F;
}

.save-card-line-separator {
  width: 100%;
  height: 0px;
  border: 1px solid #007FAD;
}

.save-card-line-separator-enabled {
  width: 100%;
  height: 0px;
  border: 1px solid #07894A;
}

.save-card-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
}

.save-card-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 456px;
}

.save-card-checkbox {
  width: 16px;
  height: 16px;
}

.save-card-checkbox-label {
  width: 432px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #242424;
}

.save-card-checkbox-label-disabled {
  width: 432px;
  height: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #767676;

}

.link-button {
  background: transparent;
  border: none;
  color: #007fad;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
}

//Mobile Devices
@media screen and (max-width: 479px) {
  .credit-debit-container {
    width: unset;
    padding: 24px;
  }

  .first-name-last-name-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px;
    grid-gap: 40px;
    gap: 24px;
  }

  .first-name-last-name-textbox {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0px;
    gap: 4px;
    width: 100%;
  }

  .credit-debit-number-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0px;
    gap: 4px;
  }

  .expiry-cvc-zip-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px;
    grid-gap: 40px;
    gap: 24px;
  }

  .expiry-cvc-zip-textbox {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0px;
    gap: 4px;
    max-width: 100%;
    width: 100%;

  }
}