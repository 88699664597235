.wallet-frame {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 40px;
  gap: 16px;
  // max-width: 90%;
  margin: 0 auto;
  //overflow-y: auto;
}

.wallet-info-credential {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Roboto, Serif;
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
}

.payment-method-row.payment-method-row {
  width: 100%;
}