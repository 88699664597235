.calendar-frame {
  max-width: 450px;
  margin: 10% 10%;
}

.save-button {
  width: 100%;
  margin: 0 auto;
  border: none;
  background-color: #007fad;
  color: white;
  border-radius: 40px;
  margin-top: 25px;
  display: block;
  height: 48px;
  font-size: 16px;
}

.payment-date-save-button-row {
  display: block;
  width: 80%;
  margin: 0 auto;
  height: fit-content;
  padding-bottom: 4rem;
}

.cut-off-time-info {
  display: flex;
  margin: 5px auto;
  font-weight: 400;
  font-size: 16px;
  font-family: Roboto, Serif;
  color: #242424;
  align-items: baseline;
  line-height: 24px;
}

.cut-off-time-info-icon {
  background-color: #007fad;
  padding: 3px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  color: white;
  vertical-align: middle;
  text-align: center;
  margin: 5px;
}

.done-button {
  width: 100%;
  margin: 0 auto;
  border: none;
  background-color: #007fad;
  color: white;
  border-radius: 40px;
  display: block;
  height: 48px;
  font-size: 16px;
}
