/****** PaymentMethod modal styles *****/

.payment-method-frame {
  width:  100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 16px;
  margin: 0 auto;
  overflow: auto;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.payment-method-button-container {
  width: 100%;

  .select-account {
    font-weight: 500;
  }
}

.payment-method-wallet-button-container {
  display: flex;       
  flex-direction: column; 
  gap: 10px; 
}

.digital-wallets-disabled-message-container{
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #FFF0D5;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
  display: inline-flex
}


.digital-wallets-disabled-message-inner-container{
  height: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex
}

.digital-wallets-disabled-image{
  text-align: center;
  color: #B68A00
}

.digital-wallets-disabled-message{
  flex: 1 1 0;
  color: #242424;
  font-size: 14px;
  line-height: 1.3;
  word-wrap: break-word
}

.wallet-option {
  width: 100%;
  color: #00344a;
  font-family: Roboto, Serif;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding: 14px 16px 14px 16px;
}

.wallet-option.new {
  color: #007fad;
}

.wallet-option:hover {
  background-color: #f1f9ff;
  cursor: pointer;
}

.card-network-logo {
  float: right;
  min-width: 60px;
  max-width: 100px;
}

.forge-scrollbar {
  z-index: 99999999999 !important;
}

//Mobile Devices
@media screen and (max-width: 479px) {
  .payment-method-button-container {
    width: 100%;
  }

  .payment-method-frame {
    width: 100%;
    padding: 24px;
  }
}