/****** Cash modal styles *****/

//DESKTOP Devices
.add-to-google-wallet-button {
    width: 150px !important;
    height: 36px;

}

.add-to-apple-wallet-button {
    width: 110px !important;
    height: 36px;
}

.cash-view {
    max-width: 75%;
    margin: 0 auto;
}

.cash-modal {
    max-width: 90%;
    margin: 0 auto;
    overflow: hidden;
}

.biller-logo-cash {
    max-height: 40px;
    display: block;
    object-fit: contain;
}

.account-display-cash {
    font-size: 14px;
    text-align: right;
}

.cash-primary-display {
    padding: 15px;
    padding-top: 10px;
    border: 1px solid #CBD2DE;
    border-radius: 8px;
    margin: 20px;
    overflow: auto;
    max-width: 520px;
}

.cash-only-primary-display {
    padding: 15px;
    padding-top: 10px;
    border: 1px solid #CBD2DE;
    border-radius: 8px;
    margin: 20px;
    overflow: auto;
}

.save-barcode-container-title {
    /* Send or save your reusable barcode */
    width: 520px;
    height: 24px;

    /* body/large bold */

    font-family: Roboto, Serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* text/dark-15 */

    color: #242424;
}

.save-barcode-container {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    width: 520px;
    height: 88px;
}

.save-barcode-icons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 20px;
    width: 100%;
    height: 40px;

}

// ******* bar code display
.barcode-container {
    width: auto;
    margin: 0 auto;
    text-align: center;
}

.barcode-column {
    display: inline-block;
    vertical-align: middle;

}

.incom-icon {
    height: 60px;
    position: relative;
    top: -4px;
}

.barcode {
    height: 68px;
    width: 250px;
    margin: 0 auto;
}

.barcode-error-container {
    margin: 20px;

}

.cash-account-logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    gap: 10px;
    overflow-wrap: break-word;
    width: 100%;
}

.cash-biller-logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cash-account-number {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ez-pay-no-barcode-content-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
}

.could-not-load-barcode {
    color: #EA032A;
    font-size: 16px;
    font-weight: 900;
    word-wrap: break-word;
    padding-top: 24px;
    line-height: 24px;
    text-align: center;
}

.send-email-frame-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 600px;
    padding: 40px;
  
    @media (max-width: 768px) {
      margin: 0px;
      width: 100%;
      height: 25vh;
      justify-content: center;
    }
}

.send-email-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border: 1px #B5B5B5 solid;
    border-radius: 8px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  
    @media (max-width: 678px) {
      justify-content: center;
    }
}

.send-sms-frame-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 600px;
    padding: 40px;
  
    @media (max-width: 768px) {
      margin: 0px;
      width: 100%;
      height: 40vh;
      justify-content: center;
    }
}

.send-sms-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border: 1px #B5B5B5 solid;
    border-radius: 8px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  
    @media (max-width: 678px) {
      justify-content: center;
    }
}

//*********

// Mobile Devices
@media screen and (max-width: 479px) {
    .save-barcode-icons-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 90px;
        width: 100%;
        height: 40px;
    }

    .incom-icon {
        padding-top: 0.2em;
        height: 50px;
        position: relative;
        top: -3px;

    }

    .barcode {
        height: 54px;
        width: 200px;
        margin: 0 auto;
    }

    .cash-primary-display {
        padding: 15px;
        padding-top: 10px;
        border: 1px solid #CBD2DE;
        border-radius: 8px;
        margin-top: 20px;
        margin-left: 1%;
        margin-right: 1%;
        overflow: auto;
        max-width: 520px;
    }

    .cash-only-primary-display {
        padding: 15px;
        padding-top: 10px;
        border: 1px solid #CBD2DE;
        border-radius: 8px;
        margin-top: 20px;
        margin-left: 1%;
        margin-right: 1%;
        overflow: auto;
        max-width: 520px;
    }

    .barcode-error-container {
        margin-top: 0px;
        margin-left: -5px;
        margin-right: -5px;
        overflow: hidden;
    }

    .cash-view {
        max-width: 90%;
        margin: 0 auto;
    }
}