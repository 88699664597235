.link-expired-frame {
  width: 90%;
  background-color: #f2f3f7;
  border-radius: 16px;
  margin: 80px auto;
}

.link-expired-inner-frame {
  max-width: 600px;
  margin: 0 auto;
  padding: 136px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
}

.link-expired-image {
  display: flex;
  justify-content: center;
}

.link-expired-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.link-expired-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px;
  text-align: center;
}

.link-expired-text-body {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.link-expired-button {
  margin: 30px 20px auto;
  display: flex;
  justify-content: center;
}
