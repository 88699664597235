.unsubscribe-frame {
    width: 60%;
    background-color: #F2F3F7;
    border-radius: 16px;
    margin: 20px auto;
}

.unsubscribe-inner-frame {
    width: 75%;
    margin: 0 auto;
    padding: 25px;
}

.unsubscribe-image {
    display: flex;
    justify-content: center;
}

.center-text-32 {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
}

.unsubscribe-text {
    display: block;
    margin: 20px 20px auto;
}

.unsubscribe-text-body {
    width:60%;
    margin: 0 auto;
    line-height: 20px !important;
}

.unsubscribe-button {
    margin: 30px 20px auto;
    display: flex;
    justify-content: center;
}