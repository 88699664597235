.receipt-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 40px;
    width: 600px;
}

.receipt-info-fields-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.receipt-info-subtitle {
  color: #242424;
  font-family: Roboto, Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.receipt-info-footer {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0
}

.receipt-info-inputs-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
    max-width: 520px;
}


.receipt-info-textbox-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    width: 100%;
}

.receipt-info-alert-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 16px;
    background: #F1F9FF;
    border: 1px solid #767676;
    border-radius: 8px;
}

.receipt-info-alert-container-enabled {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 16px;
    background: #CCFFD6;
    border: 2px solid #07894A;
    border-radius: 8px;
}

.receipt-info-alert-title-toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.receipt-info-alert-title {
    width: 408px;
    height: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #14181F;
}

.receipt-info-alert-line-separator {
    width: 472px;
    height: 0px;
    border: 1px solid #007FAD;
}

.receipt-info-alert-line-separator-enabled {
    width: 472px;
    height: 0px;
    border: 1px solid #07894A;
}

.receipt-info-alert-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
}

.receipt-info-checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 456px;
}

.receipt-info-checkbox {
    width: 16px;
    height: 16px;
}

.receipt-info-checkbox-label {
    width: 432px;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #242424;
}

.receipt-info-checkbox-label-disabled {
    width: 432px;
    height: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #767676;

}

.receipt-info-mobile-helper-text-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    width: 472px;
}

.receipt-info-mobile-helper-text {
    width: 456px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #242424;
}

.infoIcon {
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toggle-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 95%;
}

.button-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 10px;
}



//Mobile Devices
@media screen and (max-width: 479px) {

    .receipt-info-container {
      padding: 40px 25px;
      width: unset;
      height: 100vh;
      overflow: scroll;
    }

    .receipt-info-alert-title-toggle-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;

        width: 279px;
        height: 40px;
    }

    .receipt-info-alert-line-separator {
        width: 279px;
        height: 0px;
        border: 1px solid #007FAD;
    }

    .receipt-info-alert-line-separator-enabled {
        width: 279px;
        height: 0px;
        border: 1px solid #07894A;
    }

    .receipt-info-alert-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 24px;
        gap: 16px;
        width: 327px;
    }

    .receipt-info-alert-container-enabled {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 24px;
        gap: 16px;
        background: #CCFFD6;
        border: 2px solid #07894A;
        border-radius: 8px;
        width: 327px;
    }

    .receipt-info-checkbox-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        grid-gap: 8px;
        gap: 8px;
        width: 279px;
    }

    .receipt-info-mobile-helper-text-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        width: 279px;
    }

    .buttons-row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 16px;
        flex-wrap: wrap-reverse;
        width: 100%;

        align-self: stretch;
        flex-grow: 0;
    }

    .button-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding: 16px 32px;
        width: 100%;
        flex-grow: 1;


    }


}