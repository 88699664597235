.google-map {
  width: 100%;
  height: 100vh;
}

.pin-icon {
  font-size: 2rem;
  color: #007fad;
  padding-top: 5px;
  cursor: pointer;
}

.map-pin-container {
  position: "relative";
  align-self: "center";
}

.map-pin-details-content {
  padding: 15px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: -5px;
  left: -50px;
  width: 200px;
  border-radius: 12px;
  z-index: 999;
}

.retailer-logo {
  max-width: 100px !important;
  max-height: 100px !important;
}



.map-bubble-container {

  width: 280px; 
  height: fit-content; 
  padding-left: 24px; 
  padding-right: 24px; 
  padding-top: 16px; 
  padding-bottom: 16px; 
  background: white; 
  border-radius: 16px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 16px; 
  display: inline-flex;
  z-index: 9999;
  position: absolute;
  bottom: 0px;
  left: -110px;
}

.map-retailer-logo {
  max-width: 65px
}

.map-logo-close-btn-container {

  display: inline-flex;
  width: 100%;

}

.map-bubble-close-btn-container{

  width: 100%; height: 100%; 
  flex-direction: column; 
  justify-content: flex-end; 
  align-items: flex-end; 
  display: inline-flex
}
.map-bubble-close-btn {
  text-align: center; 
  color: #004C6A; 
  font-size: 24px;  
  font-weight: 900; word-wrap: break-word
}

.map-bubble-content {
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 4px; 
  display: flex

}

.map-bubble-retailer-title {
  color: #00344A; 
  font-size: 16px; 
  font-weight: 700; 
  line-height: 24px; 
  word-wrap: break-word
}

.map-bubble-address {

  color: #242424; 
  font-size: 12px; 
  font-weight: 400; 
  line-height: 20px; 
  word-wrap: break-word
}

.map-bubble-status {
  color: #588300; 
  font-size: 12px; 
  font-weight: 400; 
  line-height: 20px; 
  word-wrap: break-word
}

.map-bubble-distance {

  color: #242424; 
  font-size: 12px; 
  font-weight: 400; 
  line-height: 16px; 
  word-wrap: break-word
}

.map-close {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background: white;
  float: right;
}





@media screen and (min-width: 799px) {
  .google-map {
    height: 55vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin-icon {
    position: absolute;
    font-size: 3vw;
    color: #007fad;
  }
}
