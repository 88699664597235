.validate-links {
    margin: 0 auto;
    width: 40%;
    max-height: 350px;
    height:fit-content;
    border: none;
    

    @media (max-width: 700px) {
        width: 90%;
        height: 90%;
      }
}

.validate-links-a {
    text-align:center;
    margin-bottom: 5px;
    font-size: 10pt;
}

.bottom-link {
    border: none;
    background:none;
    color: #007FAD !important;
    font-style: normal;
    text-decoration: none;
}

.bottom-link:hover {
    text-decoration:none;
}

