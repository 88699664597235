.session-modal-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  border: 1px solid lightgrey;
  max-height: 90vh;
  background-color: white;
  opacity: 1.0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  max-width: 50%;
  min-width: 400px;
  border-radius: 24px;
  overflow: auto;
}

.session-description-container {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.session-description-text {
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, Serif;
}

.session-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}

.seconds-text {
  color: #242424;
  font-family: Roboto, Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.end-session-button {
  display: flex;
  height: 48px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    color: #001E2D
  }
}

.continue-session-button {
  display: flex;
  height: 48px;
  padding: 16px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: #007FAD;
}

@media screen and (max-width: 479px) {
  .session-modal-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid lightgrey;
    max-height: 90vh;
    background-color: white;
    opacity: 1;
    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
    min-width: 95%;
    max-width: 95%;
    border-radius: 24px;
    overflow: auto;
  }

  .session-buttons-container {
    gap: 0px;
  }
}