.cancel-payment-content-container {
  margin: 10% 10%;
}

.cancel-payment-content {
  padding-bottom: 10%;
  line-height: 24px;
}

.comfirm-cancel-modal-buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 48px;
  width: 100%;
}

.back-to-payment-dashboard {
  padding: 0px 20px;
}