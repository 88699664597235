.page-not-found-frame {
  padding-top: 40px;
  margin: 0 auto;
}

.page-not-found-inner-frame {
  width: 90%;
  background-color: #f2f3f7;
  border-radius: 16px;
  margin: 80px auto;
  padding: 88px;
}

.page-not-found-image {
  display: flex;
  justify-content: center;
}
