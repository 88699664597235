body {
  overflow: auto !important;
}

.ez-pay-balance-frame {
  margin: 0 auto;
  height: fit-content;
  border-radius: 24px;
  box-shadow: 0px 0px 12px 0px rgba(36, 36, 36, 0.33);
  margin-top: 30px;
  display: block;
  background-color: white;
  max-width: 745px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.ez-pay-balance-banner {
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
    margin: 0px;
  }
}

.radio-item {
  margin-right: 10px;
  line-height: 24px;
  vertical-align: middle;
}

.ez-pay-balance-frame-small {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  grid-gap: 16px;
  gap: 16px;
  background-color: white;
}

.ez-pay-balance-frame-small-invalid {
  margin: 0 auto;
  width: 80%;
  height: fit-content;
  // box-shadow: 3px 3px 3px 0 rgb(0 0 0 / 19%);
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  display: block;
  background-color: #ffedeb;
}

.ez-pay-balance-frame-small-no-border {
  margin: 0 auto;
  width: 80%;
  height: fit-content;
  // box-shadow: 3px 3px 3px 0 rgb(0 0 0 / 19%);
  display: block;
  background-color: white;
  line-height: 16px;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.balance-amount-text {
  width: 100px;
  float: right;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  vertical-align: middle;
  color: black;
  border: none;
  text-align: right;
}

.balance-amount-text-invalid {
  width: 100px;
  float: right;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  vertical-align: middle;
  color: black;
  border: none;
  text-align: right;
  background-color: #ffedeb;
}

.balance-amount-text-error {
  width: 100%;
  margin-left: 25px;
  text-align: left;
  vertical-align: middle;
  line-height: 17px;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #93001b;
}

.ez-pay-balance-no-frame {
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  margin-top: 35px;
  font-weight: bold;
  font-size: 16px;
  padding: 0px 96px;

  @media (max-width: 768px) {
    margin: 0 auto;
    width: 80%;
    height: fit-content;
    margin-top: 35px;
    font-weight: bold;
    font-size: 16px;
    padding: 0px;
  }
}

.ez-pay-balance-row {
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: #f5f5f5;

  @media (max-width: 768px) {
    gap: 8px;
    padding: 16px;
  }
}

.ez-pay-balance-content-right {
  float: right;
  display: inline-block;
  width: 40%;
  text-align: right;
  font-size: 16px;
}

.ez-pay-balance-content-left {
  float: left;
  display: inline-block;
  width: 40%;
  font-size: 16px;
  line-height: 2rem;
}

.ez-pay-balance-content-center {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  line-height: initial;
}

.ez-pay-balance-content-center-amount-due-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
}

.ez-pay-no-balance-content-center-amount-due-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
}

.could-not-load-balance {
  color: #EA032A;
  font-size: 18px;
  font-weight: 900;
  word-wrap: break-word;
  padding-top: 24px
}

.no-funding-source-available {
  max-width: 356px;
  color: var(--text-body-neutral-15, var(--text-body-neutral-15, #242424));
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}


.amount-due-row-tooltip {
  z-index: 1;
}

.ez-pay-balance-content-center-small {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 24px;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 11px;
    flex-direction: column;
    margin-bottom: 5px;
    gap: 9px;
    padding: 0px;
  }
}

.ez-pay-balance-content-cell {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  align-self: stretch;
  justify-content: center;
  line-height: 20px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    align-self: stretch;
    justify-content: center;
    color: #242424;
    font-family: Roboto, Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.ez-pay-scheduled-payment-content-cell {
  font-size: 16px;
  font-weight: 400;
}

.ez-pay-button-link {
  font-size: 16px;
  font-weight: 700;
  background-color: transparent;
  color: #007fad;
  border: none;
  line-height: 24px;
}

.ez-pay-balance-cell-value {
  font-size: 16px;
  font-weight: 900;
}

.ez-pay-schedule-payment-amount-cell-value {
  font-weight: 900;
}

.ez-pay-balance-label-small {
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
}

.ez-pay-balance-label-large {
  font-weight: 900;
  font-size: 56px;
  text-align: center;
}

.ez-pay-balance-divider {
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid #b1bbcd;
  clear: both;
}

.ez-pay-option-button {
  border: none;
  padding: 0px;
  color: #007fad;
  background-color: white;
  font-weight: 700;
  min-width: 0px !important;
}

.ez-pay-payment-total {
  margin: 0 auto;
  margin-top: 15px;
  width: 40%;
  height: fit-content;
  border-top: 1px solid grey;
  background-color: rgb(229, 226, 226);
  padding-left: 20px;
  padding-right: 20px;
}

.ez-pay-balance-text-small {
  font-size: 8pt;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ez-pay-balance-done {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 48px;
  padding: 16px 32px;
  border-radius: 40px;
  border: none;
  color: white;
  background-color: #007fad;
}

.ez-pay-balance-row-noframe {
  display: block;
  width: 100%;
  margin: 0 auto;
  height: fit-content;
  padding: 40px 96px;

  @media (max-width: 768px) {
    z-index: 9999;
    margin: 0 auto;
    height: fit-content;
    position: fixed;
    bottom: 0;
    display: inline-flex;
    padding: 9px 39px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background: #fff;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.15);
  }
}

.ez-pay-balance-select {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #949494;
  border-radius: 8px;
  padding: 16px;
}

.balance-amount-frame-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 600px;
  padding: 40px;

  @media (max-width: 768px) {
    margin: 0px;
    width: 100%;
    height: 100vh;
    justify-content: start;
  }
}

.balance-amount-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;

  @media (max-width: 678px) {
    justify-content: start;
  }
}

.ez-pay-balance-method-button {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #949494;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
  text-align: left;
}

.ez-pay-balance-method-button-cash {
  width: 100% !important;
  margin: 0 auto !important;
  border: 1px solid #949494 !important;
  border-radius: 0.8rem !important;
  padding: 16px !important;
  background-color: #ffffff;
  text-align: left !important;
}

.ez-pay-balance-method-button-no-border {
  margin: 0 auto;
  padding: 16px;
  background-color: #ffffff;
  border: none;
  color: #007fad;
  width: 75%;
  display: block;
}

.cash-primary-display {
  padding: 15px;
  padding-top: 10px;
  border: 1px solid #cbd2de;
  border-radius: 8px;
  margin: 20px;
  overflow: auto;
  //max-width: 500px;
}

.retailer-logos {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
}

.retailer-logo-img {
  max-width: 125px;
  object-fit: contain;
}

.retail-view {
  margin: 20px;
}

.retailer-button {
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  background: #fff;
  margin-bottom: 16px;
  cursor: pointer;
}

.retailer-standard-display {
  display: block;
  max-height: 484px;
  overflow-y: auto;
  margin-top: 40px;
}

.retailer-icon {
  float: left;
  vertical-align: middle;
  height: 100%;
  margin-right: 10px;
  display: flex;
  color: #007fad;
}

.retailer-icon-pin {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.retailer-label {
  display: flex;
  flex-direction: column;
  width: 60%;
  float: left;
  vertical-align: middle;
  line-height: 18px;
}

.retailer-location {
  float: right;
  text-align: right;
  display: flex;
}

.retailer-location-inner {
  margin: auto;
}

.retailer-result {
  color: #242424;
  font-style: italic;
  font-size: 16px;
  margin-bottom: 10px;
}

.map-button {
  float: right;
  padding: 0px !important;
  min-width: 0px !important;
  margin-top: 5px;
}

.input-zip {
  display: flex;
  width: 60%;
  margin-bottom: 15px;
}

.continue-button {
  width: 100%;
  margin: 0 auto;
  border: none;
  background-color: #007fad;
  color: white;
  border-radius: 40px;
  margin-top: 25px;
  display: block;
  height: 48px;
  font-size: 16px;
}

.ez-pay-balance-button-container {
  padding: 15px 96px 0px 96px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    width: 80%;
    padding: 15px 0px 0px 0px;
    margin: 0 auto;
  }
}

.ez-pay-balance-container-scheduled {
  padding: 15px 96px 0px 96px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  direction: ltr;
  column-gap: 0.75em;
  line-height: 20px;
  align-items: center;

  @media (max-width: 768px) {
    width: 80%;
    padding: 15px 0px 0px 0px;
    margin: 0 auto;
  }
}

.view-details {
  color: #007fad !important;
  font-family: Roboto, Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  padding-left: 5px;
  border: none;
  background: white;
}

.ez-pay-charity-container {
  padding: 15px 96px 0px 96px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    width: 80%;
    padding: 15px 0px 0px 0px;
    margin: 0 auto;
  }
}

.ez-pay-payment-total-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  gap: 16px;
}

.ez-pay-payment-total-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 96px 0px 96px;
  gap: 16px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    gap: 16px;
    margin: 0 auto;
  }
}

.ez-pay-payment-total-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  gap: 16px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    width: 80%;
  }
}

.ez-pay-payment-total-label {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.ez-pay-payment-total-amount {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.ez-pay-payment-total-amount-error {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: red;
}

.ez-pay-payment-total-label-info {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #242424;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    width: 80%;
  }
}

.ez-pay-donation-item-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 16px;
}

.ez-pay-donation-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  margin: 0 auto;
  width: 100%;
}

.ez-pay-donation-item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  margin: 0 auto;
  width: 75%;

  @media (max-width: 768px) {
    width: 80%;
  }
}

.ez-pay-donation-item-label {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.ez-pay-donation-item-amount {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
}

.retailer-empty-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.retailer-info {
  width: 410px;
  color: var(--text-dark-15, #242424);
  text-align: center;
  font-family: Roboto, Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.retailer-info-or {
  color: var(--text-dark-15, #242424);
  font-family: Roboto, Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.retailer-map-button {
  display: flex;
  height: 52px;
  padding: 16px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: var(--primary-primary-50, #007fad);
  color: white !important;
}

.retailer-option {
  width: 100%;
  color: #00344a;
  font-family: Roboto, Serif;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  padding: 14px 16px 14px 16px;
}

.retailer-option.new {
  color: #007fad;
}

.retailer-option:hover {
  background-color: #f1f9ff;
  cursor: pointer;
}

.retailer-selection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap:24px;
  width: 500px;
}
.retailer-selection-container-map {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap:24px;
  width: auto;
}

.zip-input {
  height: 80px;
  align-items: center;
  width: 50%;
  float: left;
}

.retailer-filter {
  height: 80px;
  align-items: center;
  width: 45%;
  float: right;
}

.zip-input-map {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 16px;
}

.retailer-icon-button-div {
  float: right;
  display: none;
}

.retailer-filter-map {
  height: 80px;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.retailer-select-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
}

.filter-label {
  font-weight: 500;
  font-size: 14;
  margin-bottom: 5px;
}

.retailer-mobile-toggle {
  display: none;
}

.retailer-standard-display-map {
  display: block;
}

.retailer-map-header {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 15px;
}
.view-map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  width: 35%;
}

@media screen and (max-width: 768px) {
  .zip-input {
    align-items: center;
    width: 100%;
  }

  .retailer-filter {
    height: 80px;
    align-items: center;
    width: 100%;
  }

  .retailer-view-frame {
    width: 100% !important;
    margin: 0 auto;
  }

  .retailer-info {
    padding: 0px 40px 0px 40px;
  }

  .retailer-mobile-toggle {
    display: block;
  }

  .retailer-standard-display-map {
    display: none;
  }

  .retailer-button {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: center;
    justify-content: space-evenly;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #b5b5b5;
    background: #fff;
  }

  .view-map-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    width: 35%;
  }

  .retailer-map-header {
    display: none;
    margin: 0;
  }

  .retailer-icon-button-div {
    display: inline;
    width: 20%;
    float: right;
    height: 80px;
    position: relative;
    padding-bottom: 10px;
  }

  .retailer-icon-button {
    position: absolute !important;
    bottom: 15px;
    left: 15px;
  }

  .zip-input-map {
    align-items: center;
    width: 80%;
    float: left;
  }

  .retailer-filter-map {
    display: none;
  }

  .retailer-sheet-radio-group {
    max-height: 470px;
    overflow-y: scroll;
    padding-left: 20px;
  }

  .retailer-sheet-apply {
    width: 80%;
    margin: 0 auto;
  }

  .retailer-sheet-apply-button {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .retailer-selection-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    width: auto;
  }
}
