.validate-form {
  display: flex;
  padding: 0px 56px 8px 56px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  color: #242424;
  text-align: center;
  font-family: Roboto, Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.validate-row {
  margin-top: 35px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

.validate-error {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-left: 3px solid var(--alert-alert-40, #BF0026);
  background: var(--alert-alert-95, #FFEDEB);
}

.validation-error-header {
  font-family: Roboto, Serif;
  color: var(--alert-alert-30, #93001B);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.validation-error-message {
  font-family: Roboto, Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--alert-alert-30, #93001B);
}

.validate-error-icon {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.validate-label {
  width: 100%;
  display: flex;
  font-weight: bold;
  font-size: 14px;
}

.validate-input {
  width: 100%;
  border: 1px solid lightgrey;
  margin-top: 5px;
  border-radius: 8px;
  height: 48px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}

.validate-input-invalid {
  width: 100%;
  border: 2px solid #EA032A;
  background-color: #FFEDEB;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 0 rgba(255, 1, 1, 0.19);
  height: 48px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}

.validate-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-modal-link {
  border: none;
  background-color: white;
  color: #007FAD;
  padding-top: 10px !important;
  padding-left: 0px !important;
  line-height: 16px;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10pt;
  width: 100%;
  display: flex;
}

.account-modal-link-label {
  width: 100%;
  text-align: left;
  vertical-align: middle;
  line-height: 17px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .validate-form {
    display: flex;
    padding: 0px 24px 8px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    color: #242424;
    text-align: center;
    font-family: Roboto, Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .account-modal-link {
    border: none;
    background-color: white;
    color: #007FAD;
    padding-top: 5px !important;
    padding-left: 0px !important;
    line-height: 14px;
    font-family: Roboto, Serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10pt;
    width: 100%;
    display: flex;
  }

  .account-modal-link-label {
    width: 100%;
    text-align: left;
    vertical-align: middle;
    line-height: 20px;
    font-size: 14px;
  }
}