.common-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #FFF;
  border-radius: 16px 16px 0px 0px;
  padding: 24px 0px;
  border-bottom: 1px solid #007FAD;
}

.drawer {
  width: 390px !important;
}

.ez-pay-menu {
  position: sticky;
  top: 0px;
  width: 100%;
  background-color: white;
  font-size: 1.75em;
  font-weight: lighter;
  z-index: 2;
}

.ez-pay-top-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 20px 40px;
  border-bottom: 1px solid #B5B5B5;
  background: #FFF;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
}

.ez-pay-menu-logo {
  height: 100%;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 20px;
}

.ez-pay-menu-logo-helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.ez-pay-menu-main {
  display: inline-block;
  height: 40px;
  vertical-align: middle;
}

.ez-pay-menu-header {
  border-left: 1px solid white;
  display: inline-block;
  height: 40px;
  padding-left: 10px;
  align-items: center;
}

.ez-pay-menu-hamburger {
  max-width: fit-content;
  vertical-align: middle;
  float: left;
}

.menu-hamburger-in-dropdown {
  display: flex;
  padding: 24px 0px 70px 50px;
  margin-left: 12px;
  flex-direction: column;
  align-items: flex-start;
}

.ez-pay-menu-hamburger-icon {
  width: 20px;
}

.ez-pay-menu-hamburger-button {
  background-color: #006080;
  border: none;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.ez-pay-menu-hamburger-button:hover {
  background-color: #003a4d;
}

.ez-pay-menu-hamburger-button-in-menu {
  background-color: #E3F3FF;
  border: none;
  border-radius: 50%;
  align-items: center;
  height: 40px;
  width: 40px;
}

.ez-pay-menu-hamburger-button-in-menu:hover {
  background-color: #E3F3FF;
}

.ez-pay-menu-dropdown-button {
  display: flex;
  background-color: #2A3141;
  width: 100%;
  border: none;
  padding: 20px 40px !important;
  gap: 16px;
}

.ez-pay-menu-dropdown-button-button {
  background: none;
  border: none;
  width: 100%;
  color: #E3F3FF;
}

.ez-pay-menu-dropdown {
  color: white;
  background-color: #2A3141;
  width: 390px;
  height: 100%;
  padding: 0px 0px 80px 0px;
  transition: height 0.3s ease;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
  gap: 80px;
}

.ez-pay-menu-dropdown-item {
  display: flex;
  height: 35px;
  font-size: 12pt;
  float: left;
  margin-bottom: 10px;
  width: 100%;
  gap: 16px;
}

.ez-pay-menu-dropdown-item:hover {
  background-color: #232323;
}

.ez-pay-menu-dropdown-icon {
  vertical-align: middle;
  max-height: 25px;
  max-width: 25px;
  margin: 5px;
  margin-right: 10px;
  float: left;
  text-align: left;
}


.modal-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  border-radius: 3px;
  padding: 15px;
  height: fit-content;
  background-color: white;
  opacity: 1.0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  max-width: 75%;
}

.modal-frame-about {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  padding: 15px;
  height: fit-content;
  opacity: 1.0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  max-width: 600px;
  background: linear-gradient(180deg, #C4E7FF 0%, #FFFFFF 57.29%);
  border-radius: 24px;
}

.modal-frame-contact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  min-height: 300px;
  max-height: 90%;
  background-color: white;
  opacity: 1.0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  max-width: 75%;
  min-width: 500px;
  border-radius: 24px;
  overflow: hidden;
}

.modal-frame-help {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-height: 90vh;
  background-color: white;
  opacity: 1.0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  max-width: 75%;
  min-width: 520px;
  border-radius: 24px;
  overflow: auto;
}

.modal-frame-help {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal-close-mobile {
  background: none;
  border: none;
  padding: 10px;
}

.modal-frame-help::-webkit-scrollbar {
  display: none;
}

.privacy-terms-modal-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 1.0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  width: 600px;
  max-width: 75%;
  min-width: 500px;
  height: 600px;
  max-height: 90vh;
  border-radius: 24px;
  overflow: hidden;
}

.modal-frame-maps {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  background-color: white;
  opacity: 1.0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  max-width: 75%;
  min-width: 500px;
  border-radius: 24px;
  overflow: auto;
  height: 75%;
  margin: 0 auto;
}

.modal-window {
  display: inline-grid;
  margin: 0 auto;
  min-width: 200px;
  gap: 40px;
}

.bill-modal-window {
  display: flex;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 0px 0px 24px 24px;
  background: #FFF;
}

.modal-label {
  display: flex;
  color: #007FAD;
  font-family: Roboto, Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  align-items: center;
  gap: 16px;
}

.modal-label-bill {
  font-family: Roboto, Serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #404040;
  align-self: stretch;
}

.bill-image {
  min-width: 520px;
  min-height: 240px;
}

.errorText {
  width: 100%;
  margin-top: 5px;
  margin-left: 25px;
  text-align: left;
  vertical-align: middle;
  line-height: 17px;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #93001B;
}

input:focus:not(focus-visible) {
  outline: none;
}

.biller-logo {
  margin: 0 auto;
  width: 200px;
  height: 60px;
  object-fit: contain;
}

.biller-logo-link {
  width: 100%;
  max-height: inherit;
  margin: 0 auto;
  align-content: center;
  display: block;
  border: none;
  background-color: #FFF;
}

.biller-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: center;
  background: #FFF;
  height: 60px;
  width: 100%;
  max-height: 60px;
  border-radius: 16px 16px 0px 0px;
}

.footer-container {
  position: fixed;
  bottom: 0px;
  background-color: lightgrey;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
}

.footer-left {
  float: left;
  width: 50%;
  color: black;
  font-size: 8pt;
  padding-left: 3px;
}

.footer-right {
  float: right;
  width: 50%;
  padding-right: 3px;
}

.footer-left-link {
  color: #006080;
  text-decoration: underline;
  border: none;
  background-color: lightgrey;
  font-size: 8pt;
}

.footer-right-link {
  float: right;
  font-size: 8pt;

}

.ez-pay-menu-greyout {
  background-color: rgba(36, 36, 36, 0.96) !important;
}

.carousel-base {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.carousel-image {
  min-width: 100px;
  min-height: 100px;
  margin: 0 auto;
}

.carousel-card {
  min-width: 100px;
  min-height: 100px;
  margin: 0 auto;
  width: 100%;
}

.carousel-header {
  margin: 0 auto;
  width: 80%;
  text-align: center;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 15px;
}

.carousel-text {
  margin: 0 auto;
  width: 80%;
  text-align: center;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.slick-dots li button:before {
  font-family: Roboto, Serif;
  font-size: 10px !important;
  line-height: 40px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
}

.carousel-bottom-buttons {
  width: 45%;
  min-width: 200px;
  height: 48px;
  margin: auto;
  margin-top: 50px;
}

.carousel-bottom-buttons-left {
  float: left;
  align-content: left;
}

.carousel-bottom-buttons-right {
  float: right;
  align-content: right;
}

.carousel-arrow-button {
  width: 120px;
  background: none;
  height: 48px;
  border: none;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #007FAD;
  vertical-align: middle;
}

.carousel-arrow-button-arrow {
  margin: -2px 4px;
}

.carousel-done-button {
  border: 2px solid #007FAD;
  border-radius: 40px;
  width: 120px;
  background: none;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #007FAD;
}

.accordion-item {
  display: block;
  width: 600px;
  min-height: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: fit-content;
  padding: 24px 24px 24px 16px;
  border-bottom: 1px solid var(--neutral-neutral-80, #B5B5B5);
}

.help-modal-chevron {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.modal-header {
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
  border-radius: 24px 24px 0px 0px;
  border-bottom: 1px solid #D7D7D7;
  background: #EBEBEB;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.modal-header-with-back-button {
  background: #EBEBEB;
  height: 72px;
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid #D7D7D7;
  border-radius: 24px 24px 0px 0px;
  line-height: 20px;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.help-modal-header-text {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #242424;
  float: left;
}

.modal-icon {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-close {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background: #EBEBEB;
  float: right;
}

.modal-close-back {
  height: 24px;
  width: 24px;
  float: left;
  line-height: 100%;
  background: none;
  border: none;
  padding: 0px;
  margin-right: 5px;
  color: var(--primary-primary-50, #007FAD);
}

.modal-close-x {
  max-width: 24px;
}

.contact-modal-panel {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 0px 0px 24px 24px;
  background: #FFF;
}

.help-modal-accordion {
  padding: 40px;
}

.accordion-title {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
}

.accordion-title-label {
  margin-left: 15px;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
}

.accordion-content {
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-height: 50px;
  overflow-y: auto;
  padding: 10px;
  min-height: 120px;
}

// mobile modal sheets
.react-modal-sheet-container {
  border-radius: 24px 24px 0px 0px !important;
}

.react-modal-sheet-header {
  border-radius: 24px 24px 0px 0px !important;
  background: #EBEBEB;
  height: 72px;
  border-bottom: 1px solid #D7D7D7;
  padding: 20px;
  width: 100%;
}

.react-modal-sheet-content {
  overflow-y: auto;
}

.center-text-16 {
  text-align: center;
  font-size: 16px;
}

.left-text-16 {
  text-align: left;
  font-size: 16px;
}

.center-text-14 {
  text-align: center;
  font-size: 14px;
}

.left-text-14 {
  text-align: left;
  font-size: 14px;
}

.center-text-12 {
  text-align: center;
  font-size: 12px;
}

.left-text-12 {
  text-align: left;
  font-size: 12px;
}

.center-text-10 {
  text-align: center;
  font-size: 10px;
}

.left-text-10 {
  text-align: left;
  font-size: 10px;
}

.terms-modal {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  overflow: auto;
  height: 85%;
}

.long-text-scroll {
  min-height: 55vh;
  max-height: 90vh;
  color: #14181F;
  font-family: Roboto, Serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.clear-both {
  clear: both;
}

.error-frame {
  min-height: 300px;
  min-width: 300px;
  width: 35%;
  height: 35%;
  margin: 0 auto;
  margin-top: 20px;
  border: 2px solid #EA032A;
  background-color: #FFEDEB;
  color: black;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 0 rgba(255, 1, 1, 0.19);
  text-align: center;
}

.dollar-large {
  font-family: Roboto, Serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  color: #949494;
  float: right;
}

.content-row {
  width: 90%;
  margin: 0 auto;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 10px;
  clear: both;
  margin-bottom: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.content-row-left {
  float: left;
  width: 40%;
  padding-right: 10px;
}

.content-row-left-button {
  float: left;
  padding-right: 10px;
}

.content-row-right {
  float: right;
  width: 40%;
  padding-left: 10px;
}

.content-row-right-button {
  float: right;
  padding-left: 10px;
}

.barcode-button-container {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

}

.barcode-button {
  background-color: #FFFFFF;
  border: none;
  width: 100%;
  padding: 0px;
}

.barcode-hidden {
  display: none;
  margin: 20px;
  overflow: auto;
}

.icon-button {
  background-color: white;
  color: #007FAD;
}

.text-button {
  margin: 0 auto;
  display: block !important;
  background-color: white;
  color: #007FAD;
}

.send-button {
  float: left;
  background-color: #007FAD;
  color: white;
}

.cancel-button {
  float: right;
  background-color: white;
  color: #007FAD;
  border: none;
}

.language-modal {
  padding: 2.4rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4rem;
}

.language-modal-save-button {
  width: 100%;
}

.error-container {
  margin-top: 0px;
  margin-left: -5px;
  margin-right: -5px;
  overflow: hidden;
}

.footer-links-container {
  width: 90%;
  font-size: 9pt;
  padding-top: 5px;
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.need-help-text {
  color: #242424;
  font-family: Roboto, Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.contact-us-text {
  background-color: white;
  border: none;
  color: #007fad;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-family: Roboto, Serif;
  font-size: 16px;
  line-height: 24px;
  min-width: 0px !important;
}

.terms-text {
  background-color: white;
  border: none;
  color: #007fad;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700 !important;
  font-family: Roboto, Serif;
  font-size: 12px !important;
  line-height: 24px !important;
}

.footer-link-button {
  background-color: white;
  border: none;
  color: #007fad;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
}



// start Welcome Message

.welcome-modal-body {
  align-items: center;
  text-align: center;
  gap: 2.4rem;
}

.welcome-modal-close-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.welcome-modal-image {
  width: 200px;
  height: auto;
}

.welcome-modal-body-text-header {
  margin-top: 1.6rem;
  color: #2A3141;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  word-wrap: break-word
}

.welcome-modal-text-content {
  overflow-y: auto;
  max-height: 350px;
}
.welcome-modal-body-text {
color: #14181F;
font-size: 16px;
font-weight: 400;
line-height: 24px;
word-wrap: break-word
}

.welcome-modal-body button.button-filled {
  width: 200px;
  height: 48px;
}

.welcome-banner-div {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}
.welcome-banner-container {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #242424;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  margin-top: 20px;
}
.welcome-banner-icon-container {
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex
}

.welcome-banner-icon {
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 900;
  word-wrap: break-word
}

.welcome-banner-message-container {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex
}

.welcome-banner-message {
  align-self: stretch;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word
}

.welcome-banner-link {
  text-align: right;
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  line-height: 20px;
  word-wrap: break-word
}

.welcome-banner-link .button-text {
  text-align: right;
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  line-height: 20px;
  word-wrap: break-word
  
}

.welcome-banner-link .fsUcrk:not(:disabled):hover {
  color: white;
}

// end Welcome Message

//mobile changes

@media screen and (max-width: 479px) {
  .modal-frame-help {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-height: 90vh;
    background-color: white;
    opacity: 1;
    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
    min-width: 95%;
    max-width: 95%;
    border-radius: 24px;
    overflow: auto;
  }
  .modal-container>div>div {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .welcome-modal-image {
    width: 120px;
    height: auto;
  }
  .welcome-modal-text-content {
    overflow-y: auto;
    max-height: 500px;
}
.welcome-modal-body-text-header {
  margin-top: 1.6rem;
  color: #2A3141;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  word-wrap: break-word
}

.welcome-modal-body-text {
  overflow-y: auto;
  max-height: calc(100vh - 400px);
  }

.welcome-modal-button-container {
  width: 98%;
    height: 65px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    display: inline-flex;
    position: absolute;
    bottom: 2px;
    left: 2px;
    right: 2px;
    border-radius: 6px;
}
.welcome-modal-body button.button-filled {
  width: 200px;
  height: 48px;
  position: absolute;
}
}

@media screen and (max-width: 768px) {
  .carousel-bottom-buttons {
    width: 85%;
    min-width: 300px;
    height: 48px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .accordion-content {
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: Roboto, Serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    max-height: 50px;
    overflow-y: auto;
    padding: 5px;
    min-height: 120px;
  }

  .accordion-item {
    display: block;
    width: 100%;
    min-height: 40px;
    margin-bottom: 10px;
    margin-top: 20px;
    height: fit-content;
    border-bottom: 1px solid #B5B5B5;
  }

  .help-modal-accordion {
    padding: 24px;
  }

  .terms-modal {
    display: flex;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    overflow: auto;
  }

  .long-text-scroll {
    overflow-y: scroll;
    height: 100%;
    max-height: 100%;
  }

  .common-header {
    margin: 0 auto;
    border-radius: 5px;
    border-radius: 0px;
    padding-bottom: 15px;
    background-color: #FFF;
  }

  .biller-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: center;
    background: #FFF;
    width: 100%;
    height: 60px;
    max-height: 60px;
    border-radius: 16px 16px 0px 0px;
  }

  .menu-hamburger-in-dropdown {
    display: flex;
    padding: 24px 0px 70px 35px;
    margin-left: 12px;
    flex-direction: column;
    align-items: flex-start;
  }

  .ez-pay-menu-dropdown-button {
    display: flex;
    background-color: #2A3141;
    width: 100%;
    border: none;
    padding: 20px 30px !important;
    gap: 16px;
  }

  .ez-pay-menu-dropdown-item {
    display: block;
    font-size: 12pt;
    float: left;
    margin-bottom: 10px;
    width: 100%;
    padding: 20px;
  }

  .ez-pay-menu-dropdown-icon {
    vertical-align: middle;
    max-height: 25px;
    max-width: 25px;
    margin: 5px;
    margin-right: 16px;
    float: left;
    text-align: left;
  }

  .modal-label-bill {
    font-family: Roboto, Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    align-self: stretch;
  }

  .bill-modal-window {
    padding: 24px;
  }

  .bill-image {
    min-height: initial;
    min-width: initial;
    width: 100%;
  }

  .contact-modal-panel {
    padding: 24px 40px;
  }

  .footer-links-container {
    width: 90%;
    font-size: 9pt;
    padding-top: 10px;
    text-decoration: none;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 50px;
  }
}