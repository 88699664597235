.charities-container {
  display: flex;
  gap: 2rem;

  @media only screen and (max-width: 415px) {
    flex-direction: column;
    gap: 0px;
  }
}

.charities-content {
  display: flex;
  flex-direction: column;
  line-height: 5rem;

  &>div {
    gap: 1.6rem;
  }

  @media only screen and (max-width: 415px) {
    &>div {
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
    }
  }
}

.PaymentInput {
  box-sizing: border-box;
  width: 100%;
}

.charity-checkbox-label {
  display: flex;
  padding: 5px 5px 5px 0px;
}

.charity-sub-title {
  font-size: 14px;
  font-weight: 700;
  color: #242424;
  line-height: 20px
}

.charity-link {
  padding: 3px;
  padding-left: 5px;
}

.charity-modal-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  border: 1px solid lightgrey;
  max-height: 90vh;
  background-color: white;
  opacity: 1.0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
  max-width: 50%;
  min-width: 400px;
  border-radius: 24px;
  overflow: auto;
}

.charity-description-container {
  display: flex;
  padding: 24px 40px 40px 40px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.charity-description-text {
  color: #242424 !important;
  text-align: center;
  font-family: Roboto, Serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.close-button {
  display: flex;
  width: 172px;
  height: 48px;
  padding: 16px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 24px;
  color: white;
  background-color: #007fad;
}

@media screen and (max-width: 479px) {
  .charity-modal-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid lightgrey;
    max-height: 90vh;
    background-color: white;
    opacity: 1;
    box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 19%);
    min-width: 95%;
    max-width: 95%;
    border-radius: 24px;
    overflow: auto;
  }

  .close-button {    
    width: 85px;
    padding: 1px 3px;
  }


}