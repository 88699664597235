.bank-account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 40px 40px 40px;
    width: 600px;
    max-width: 600px;
    gap: 24px;
}

.bank-account-subtitle {
    font-family: Roboto, Serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.bank-account-help-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.bank-account-help-image {
    width: 90%;
    margin: 0 auto;
}

.bank-account-inputs-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.bank-account-accordion {
  padding: 2.4rem 0 2.4rem 0;
}

.bank-account-name-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    width: 100%;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.bank-account-name-inputs-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%
}

.bank-account-name-label-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.bank-account-name-label {
    font-family: Roboto, Serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.bank-account-name-textbox-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: flex-start;
    gap: 4px;
    width: 100%;
}

.bank-account-routing-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 0px 24px 0px;
    width: 100%;
}

.bank-account-routing-textbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    width: 47%;
}

.save-bank-account-inputs-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
    max-width: 520px;
}

.save-bank-account-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 16px;
    background: #F1F9FF;
    border: 1px solid #767676;
    border-radius: 8px;
}

.save-bank-account-container-enabled {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 16px;
    background: #CCFFD6;
    border: 2px solid #07894A;
    border-radius: 8px;
}

.save-bank-account-title-toggle-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    justify-content: space-between;
}

.save-bank-account-title {
    height: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #14181F;
}

.save-bank-account-line-separator {
    width: 100%;
    height: 0px;
    border: 1px solid #007FAD;
}

.save-bank-account-line-separator-enabled {
    width: 100%;
    height: 0px;
    border: 1px solid #07894A;
}

.save-bank-account-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
}

.save-bank-account-checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 456px;
}

.save-bank-account-checkbox {
    width: 16px;
    height: 16px;
}

.save-bank-account-checkbox-label {
    width: 432px;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #242424;
}

.save-bank-account-checkbox-label-disabled {
    width: 432px;
    height: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #767676;

}

.link-button {
    background: transparent;
    border: none;
    color: #007fad;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 700;
  }

.buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    // gap: 48px;
    width: 95%;
}

.button-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 10px;
}



//Mobile Devices
@media screen and (max-width: 479px) {
    .bank-account-container {
      width: unset;
      padding: 24px;
    }

    .bank-account-routing-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0px;
        grid-gap: 40px;
        gap: 24px;
        width: 100%;
    }

    .bank-account-routing-textbox {
      width: 100%;
    }

    .buttons-row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 16px;
        flex-wrap: wrap-reverse;
        width: 100%;

        align-self: stretch;
        flex-grow: 0;
    }

    .button-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding: 16px 32px;
        width: 100%;
        flex-grow: 1;


    }
}