// COMPONENTS
@import 'components/index.scss';

.ez-pay-background {
    background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    background-attachment: fixed;
}

.react-modal-sheet-backdrop {
  pointer-events: all !important;
}
