.review-charges-body {
  width: 600px;
}

.review-charges-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px;
    gap: 24px;
    width: 100%;
}

.review-charges-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.review-charges-title {
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    color: #242424;
}

.review-charges-sub-title {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #242424;
    line-height: 24px;
}

.review-charges-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: 100%
}

.review-charges-details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 30px;
}

.review-charges-left-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.review-charges-right-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 16px;
}

.review-charges-left-accno-label-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.review-charges-left-label {
    //width: 178px;
    font-weight: 700;
    font-size: 16px;
    color: #242424;
    line-height: 24px;
}

.review-charges-left-service-fee-label-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.review-charges-right-accno-value-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.review-charges-right-label {
    // width: 90px;
    font-weight: 400;
    font-size: 16px;
    color: #242424;
    text-align: end;
    line-height: 24px;
}

.review-charges-payment-total-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 40px;
    background: #555555;
    border-radius: 0px;
}

.review-charges-payment-total-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.review-charges-payment-total-value {
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    text-align: right;
    color: #FFFFFF;
}

.review-charges-bottom-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 24px 0 40px;
}

.review-charges-bottom-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
}

.buttons-row-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  align-self: stretch;
  padding: 0px 24px;
  gap: 16px;
  width: 100%;
}

.button-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 10px;
}

.error-banner {
  display: flex;
  margin: 20px 24px;
  align-items: center;
  gap: 24px;
}

.bxsuhp {
  border-radius: 8px;
  border: 2px solid #EA032A !important;
  background: #FFEDEB;
}

.tvPsi {
  font-size: 1.4rem !important;
}

//Mobile Devices
@media screen and (max-width: 479px) {
    .review-charges-body {
      width: unset;
      height: 100vh;
      overflow: scroll;
    }

    .review-charges-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 24px 24px;
        gap: 8px;
    }

    .review-charges-title-container {
        display: flex;
        padding-top: 20px;
        gap: 8px;
    }

    .review-charges-title {
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
        color: #242424;
    }

    .review-charges-sub-title {
        //display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #242424;
    }

    // .review-charges-details-container {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: flex-start;
    //     padding-top: 50px;
    //     margin-top: 30px;
    //     padding-bottom: 10px;

    //     width: 100%;
    //     height: 100%;
    // }

    .review-charges-left-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        //height: 264px;
    }

    .review-charges-right-column {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0px;
        gap: 16px;
        //height: 264px;
    }

    .review-charges-left-accno-label-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
    }

    .review-charges-left-label {
        font-weight: 700;
        font-size: 16px;
        color: #242424;
    }

    .review-charges-left-service-fee-label-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
    }

    .review-charges-right-accno-value-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0px;
        gap: 16px;

        margin-top: 10px;

    }

    .review-charges-right-label {
        font-weight: 400;
        font-size: 16px;
        color: #242424;
        text-align: end;
        line-height: 24px;
    }

    .review-charges-payment-total-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: #555555;
      border-radius: 0px;
      padding: 16px 24px;
    }

    .review-charges-payment-total-label {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .review-charges-payment-total-value {
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        text-align: right;
        color: #FFFFFF;

    }

    .review-charges-bottom-text-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 24px 24px 0 24px;
    }

    .review-charges-bottom-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #242424;
    }

    .buttons-row-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 16px;
        flex-wrap: wrap-reverse;
        align-self: stretch;
        flex-grow: 0;
        width: 100%;
    }

    .button-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding: 16px 32px;
        flex-grow: 1;


    }


}